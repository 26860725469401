import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {CcoopApiService} from '../../api/services/ccoop-api.service';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {DataActions} from '../../data/store/data.actions';
import {NavigationService} from './navigation.service';
import {SetValueAction} from 'ngrx-forms';
import {PartnerService} from '../../shared/services/partner.service';

@Injectable()
export class ProspectResolver implements Resolve<any> {
  constructor(
    private store: Store,
    private ccoopApiService: CcoopApiService,
    private partnerService: PartnerService,
    private navigationService: NavigationService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const bearerToken = route.queryParams['bearerToken'];
    if (!bearerToken) return;

    try {
      this.ccoopApiService.setToken(bearerToken);
      const prospect = await this.ccoopApiService.authProspect$().toPromise();

      this.store.dispatch(
        DataActions.setOffer({
          offer: {
            longTitle: prospect.offer,
            formula: {formula: prospect.formula, card: prospect.card},
          },
        }),
      );
      this.store.dispatch(new SetValueAction('EMAIL.email', prospect.email));
      this.store.dispatch(DataActions.setReferralCode({referralCode: prospect.referralCode}));
      this.store.dispatch(new SetValueAction('EMAIL.code', prospect.code));
      if (prospect.code && prospect.partner) {
        this.store.dispatch(new SetValueAction('EMAIL.code', prospect.code));
        const partnerOffers = await this.partnerService
          .fetchPartnerOffersByCode$(prospect.code)
          .toPromise();
        this.partnerService.setValidPartnerOffers(partnerOffers);
      }

      this.store.dispatch(
        DataActions.setSelectedTrackingChoices({trackingChoice: prospect.trackingChoice}),
      );
      this.store.dispatch(DataActions.setSelectedAssociation({association: prospect.association}));
      this.store.dispatch(new SetValueAction('DONATION.donation', prospect.donation));
      this.store.dispatch(
        new SetValueAction('DONATION.donationPerOperation', prospect.donationPerOperation),
      );
      this.store.dispatch(new SetValueAction('DONATION.donationCeiling', prospect.donationCeiling));

      if (prospect.offer === undefined) {
        return this.navigationService.goToFormulaChoices();
      }

      if (prospect.formula === undefined) {
        return this.navigationService.goToFormulaChoices();
      }

      if (prospect.card === undefined) {
        return this.navigationService.goToFormulaChoices();
      }

      if (prospect.trackingChoice === undefined) {
        return this.navigationService.goToCommitmentPrinciples();
      }

      if (prospect.association === undefined) {
        return this.navigationService.goToCommitmentCard();
      }

      if (prospect.donation === undefined) {
        return this.navigationService.goToDonationChoices();
      }

      return this.navigationService.goToSumary();
    } catch (err) {
      this.ccoopApiService.setToken(undefined);
    }
  }
}
