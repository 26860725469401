import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {ReferentielService} from '../../data/services/referentiel.service';
import {first} from 'rxjs/operators';
import {AppRoutes} from '../models/Routes.enum';
import {Store} from '@ngrx/store';
import {DataActions} from '../../data/store/data.actions';
import {TransverseReferentiel} from '../../../../../../models/Referentiel';
import {ReferralCodeValidator} from '../../shared/helper/referral-code-validator.ts';

const LOCAL_STORAGE_KEY_CODE_PAR = 'codePar';

@Injectable({
  providedIn: 'root',
})
export class CodeParGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly store: Store,
    private readonly referentielService: ReferentielService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const codePar = this.getCodeParFromQueryParams(route.queryParams);
    if (this.isCodeParValid(codePar)) {
      this.storeCodeParInLocalStorage(codePar);
      await this.redirectToReferralLink();
      return false;
    }
    return true;
  }

  private getCodeParFromQueryParams(queryParams: Record<string, any>): string | null {
    return queryParams[LOCAL_STORAGE_KEY_CODE_PAR] || null;
  }

  private isCodeParValid(codePar: string | null): boolean {
    if (codePar) {
      return ReferralCodeValidator.isValid(codePar);
    }
    return false;
  }

  private storeCodeParInLocalStorage(codePar: string): void {
    localStorage.setItem(LOCAL_STORAGE_KEY_CODE_PAR, codePar);
  }

  private async redirectToReferralLink(): Promise<void> {
    try {
      this.store.dispatch(DataActions.fetchTransverseLocalReferentiel());
      const transverseReferentiel = await this.referentielService.transverseReferentiel$
        .pipe(first())
        .toPromise();
      this.handleRedirect(transverseReferentiel);
    } catch (error) {
      this.handleError(error);
    }
  }

  private handleRedirect(transverseReferentiel: TransverseReferentiel): void {
    const redirectUrl = transverseReferentiel?.referralRedirectLink;
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }

  private handleError(error: any): void {
    console.error('Error during redirection:', error);
    this.navigateToErrorPage();
  }

  private navigateToErrorPage(): void {
    this.router.navigate([AppRoutes.ERROR]);
  }
}
