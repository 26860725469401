import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageReferralAccessGuard implements CanActivate {
  constructor(private readonly router: Router) {}

  canActivate(): boolean {
    return this.checkReferralCode();
  }

  private checkReferralCode(): boolean {
    const codePar = localStorage.getItem('codePar');
    if (!codePar) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
