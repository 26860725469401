/**
 * Classe de validation pour les codes de parrainage.
 */
export class ReferralCodeValidator {
  private static readonly codePattern: RegExp = /^[a-zA-Z0-9]{8}$/;

  public static isValid(code: string): boolean {
    return this.codePattern.test(code);
  }

  public static getErrorMessage(code: string): string {
    if (code.length !== 8) {
      return 'Le code de parrainage doit contenir exactement 8 caractères.';
    }
    if (!this.codePattern.test(code)) {
      return 'Le code de parrainage doit être alphanumérique.';
    }
    return '';
  }
}
