import {createAction, props} from '@ngrx/store';
import {PartnerOffer, ReferralReferentiel} from '../../../../../../models/Referentiel';
import {
  AssociationChoicesReferentiel,
  CommitmentAccountReferentiel,
  CommitmentCardReferentiel,
  CommitmentPrinciplesReferentiel,
  DonationChoicesReferentiel,
  FinalReferentiel,
  FormulaChoicesReferentiel,
  ErrorReferentiel,
  MailReferentiel,
  MailSavedReferentiel,
  StepsPresentationReferentiel,
  SummaryReferentiel,
  TrackingChoicesReferentiel,
  TransverseReferentiel,
} from '../../../../../../models/Referentiel';

export class DataActions {
  public static startLoading = createAction('[startLoading]');
  public static stopLoading = createAction('[stopLoading]');
  public static saveProspect = createAction('[saveProspect] save prospect');
  public static setTitle = createAction('[SetTitle] set title', props<{title: string}>());
  public static setTransverseReferentiel = createAction(
    '[SetTransverseReferentiel] set transverse referentiel',
    props<{referentiel: TransverseReferentiel}>(),
  );
  public static setLinkExpiredReferentiel = createAction(
    '[SetLinkExpired] set link expired referentiel',
    props<{referentiel: ErrorReferentiel}>(),
  );
  public static setBrowserErrorReferentiel = createAction(
    '[SetBrowserError] set Browser Error referentiel',
    props<{referentiel: ErrorReferentiel}>(),
  );
  public static setReferralReferentiel = createAction(
    '[SetReferralReferentiel] set referral referentiel',
    props<{referentiel: ReferralReferentiel}>(),
  );
  public static setStepsPresentationReferentiel = createAction(
    '[SetStepsPresentationReferentiel] set steps presentation referentiel',
    props<{referentiel: StepsPresentationReferentiel}>(),
  );
  public static setMailReferentiel = createAction(
    '[setMailReferentiel] set mail referentiel',
    props<{referentiel: MailReferentiel}>(),
  );
  public static setEmail = createAction('[setMail] set mail', props<{email: string}>());
  public static setReferralCode = createAction(
    '[setReferralCode] set referral code',
    props<{referralCode: string}>(),
  );
  public static setReferralCodeSuccess = createAction(
    '[Referral Code] Set Referral Code Success',
    props<{referralCode: string}>(),
  );
  public static setReferralCodeFailure = createAction(
    '[Referral Code] Set Referral Code Failure',
    props<{error: string}>(),
  );

  public static setCode = createAction('[setCode] set code', props<{code: string}>());
  public static setPartner = createAction('[setPartner] set partner', props<{partner: string}>());
  public static setPartnerOffers = createAction(
    '[setPartnerOffers] set partner offers',
    props<{partnerOffers: PartnerOffer[]}>(),
  );
  public static setMailSavedReferentiel = createAction(
    '[setMailSavedReferentiel] set mail saved referentiel',
    props<{referentiel: MailSavedReferentiel}>(),
  );
  public static setFormulaChoiceReferentiel = createAction(
    '[setFormulaChoiceReferentiel] Fetch formula choices referentiel',
    props<{referentiel: FormulaChoicesReferentiel}>(),
  );
  public static setOffer = createAction(
    '[setOffer] Set offer',
    props<{offer: {longTitle: string; formula: {formula: string; card: string}}}>(),
  );
  public static setCommitmentPrinciplesReferentiel = createAction(
    '[setCommitmentPrinciplesReferentiel] Fetch commitment principle referentiel',
    props<{referentiel: CommitmentPrinciplesReferentiel}>(),
  );
  public static setCommitmentAccountReferentiel = createAction(
    '[setCommitmentAccountReferentiel] Fetch commitment account referentiel',
    props<{referentiel: CommitmentAccountReferentiel}>(),
  );
  public static setTrackingChoicesReferentiel = createAction(
    '[setTrackingChoicesReferentiel] Fetch tracking choice referentiel',
    props<{referentiel: TrackingChoicesReferentiel}>(),
  );
  public static setSelectedTrackingChoices = createAction(
    '[setSelectedTrackingChoices] Define trackignChoice',
    props<{trackingChoice: string}>(),
  );
  public static setCommitmentCardReferentiel = createAction(
    '[setCommitmentCardReferentiel] Fetch commitment card referentiel',
    props<{referentiel: CommitmentCardReferentiel}>(),
  );
  public static setAssociationChoicesReferentiel = createAction(
    '[setAssociationChoicesReferentiel] Fetch association choices referentiel',
    props<{referentiel: AssociationChoicesReferentiel}>(),
  );
  public static setSelectedAssociation = createAction(
    '[setSelectedAssociation] set selected association',
    props<{association: string}>(),
  );
  public static setDonationChoicesReferentiel = createAction(
    '[setDonationChoicesReferentiel] Fetch donation choices referentiel',
    props<{referentiel: DonationChoicesReferentiel}>(),
  );
  public static setDonation = createAction(
    '[setDonation] set donation',
    props<{donation: boolean; donationPerOperation: number; donationCeiling: number}>(),
  );
  public static setDonationWithValidation = createAction(
    '[setDonationWithValidation] set donation',
    props<{donation: boolean; donationPerOperation: number; donationCeiling: number}>(),
  );
  public static validateDonation = createAction(
    '[validateDonation] validate donation',
    props<{donation: boolean; donationPerOperation: number; donationCeiling: number}>(),
  );
  public static switchDonation = createAction('[switchDonation] switch donation');
  public static initDonation = createAction('[initDonation] init donation');
  public static setSummaryReferentiel = createAction(
    '[setSummaryReferentiel] Fetch summary referentiel',
    props<{referentiel: SummaryReferentiel}>(),
  );
  public static kycRedirected = createAction('[kycRedirected] kyc redirected');
  public static setFinalReferentiel = createAction(
    '[setFinalReferentiel] Fetch final referentiel',
    props<{referentiel: FinalReferentiel}>(),
  );

  public static fetchErrorLinkExpired = createAction('[ErrorLinkExpired] Fetch link expired');
  public static fetchErrorBrowserError = createAction('[BrowserError] Fetch browser error');
  public static fetchTransverseLocalReferentiel = createAction(
    '[TransverseReferentiel] Fetch transverse referentiel',
  );
  public static fetchReferralReferentiel = createAction(
    '[ReferralReferentiel] Fetch referral referentiel',
  );
  public static fetchStepsPresentationReferentiel = createAction(
    '[StepsPresentationReferentiel] Fetch steps presentation referentiel',
  );
  public static fetchMailReferentiel = createAction(
    '[fetchMailReferentiel] Fetch mail referentiel',
  );
  public static fetchMailSavedReferentiel = createAction(
    '[fetchMailSavedReferentiel] Fetch mail saved referentiel',
  );
  public static fetchFormulaChoiceReferentiel = createAction(
    '[fetchFormulaChoiceReferentiel] Fetch formula choices referentiel',
  );
  public static fetchCommitmentPrinciplesReferentiel = createAction(
    '[fetchCommitmentPrinciplesReferentiel] Fetch commitment principle referentiel',
  );
  public static fetchCommitmentAccountReferentiel = createAction(
    '[fetchCommitmentAccountReferentiel] Fetch commitment account referentiel',
  );
  public static fetchTrackingChoicesReferentiel = createAction(
    '[fetchTrackingChoicesReferentiel] Fetch tracking choice referentiel',
  );
  public static fetchCommitmentCardReferentiel = createAction(
    '[fetchCommitmentCardReferentiel] Fetch commitment card referentiel',
  );
  public static fetchAssociationChoicesReferentiel = createAction(
    '[fetchAssociationChoicesReferentiel] Fetch association choices referentiel',
  );
  public static fetchDonationChoicesReferentiel = createAction(
    '[fetchDonationChoicesReferentiel] Fetch donation choices referentiel',
  );
  public static fetchSummaryReferentiel = createAction(
    '[fetchSummaryReferentiel] Fetch summary referentiel',
  );
  public static fetchFinalReferentiel = createAction(
    '[fetchFinalReferentiel] Fetch final referentiel',
  );
}
