import {DataState} from './DataState';
import {Prospect} from '../../../../../../models/Prospect';

// Grande aide avec NGRX forms
// https://github.com/MrWolfZ/ngrx-forms/blob/master/example-app/src/app

export const prospectSelector = (state: DataState) => {
  return {
    referralCode: state?.referralPage?.referralCode,
    email: state?.mailPage?.mailForm.controls.email.value,
    offer: state?.formulaChoicesPage?.offer?.longTitle,
    formula: state?.formulaChoicesPage?.offer?.formula?.formula,
    card: state?.formulaChoicesPage?.offer?.formula?.card,
    code: state?.mailPage?.partner?.code,
    partner: state?.mailPage?.partner?.name,
    trackingChoice: state?.trackingChoicesPage?.trackingChoice,
    association: state?.associationChoicesPage?.association,
    donation: state?.donationChoicesPage?.donationForm.controls.donation.value,
    donationPerOperation:
      state?.donationChoicesPage?.donationForm.controls.donationPerOperation.value || undefined,
    donationCeiling:
      state?.donationChoicesPage?.donationForm.controls.donationCeiling.value || undefined,
    kycRedirect: state?.summaryPage?.kycRedirected,
  } as Prospect;
};

export const loadingSelector = (state: DataState) => state.loading;
export const transverseReferentielSelector = (state: DataState) => state.transverse;
export const errorReferentielSelector = (state: DataState) => state?.errorPage?.referentiel;
export const helpReferentielSelector = (state: DataState) => state.transverse.helpReferentiel;
export const quitReferentielSelector = (state: DataState) => state.transverse.quitReferentiel;
export const referralReferentielSelector = (state: DataState) => state.referralPage?.referentiel;
export const stepsPresentationReferentielSelector = (state: DataState) =>
  state.stepsPresentationPage?.referentiel;
export const supportingDocumentsReferentielSelector = (state: DataState) =>
  state.stepsPresentationPage?.referentiel?.supportingDocumentsReferentiel;
export const infoLegalesModaleReferentielSelector = (state: DataState) =>
  state.transverse?.infoLegalesModale;
export const eligibilityReferentielSelector = (state: DataState) =>
  state.stepsPresentationPage?.referentiel?.eligibilityReferentiel;
export const mailReferentielSelector = (state: DataState) => state?.mailPage?.referentiel;
export const mailRgpdReferentielSelector = (state: DataState) =>
  state?.mailPage?.referentiel.rgpdReferentiel;
export const mailFormSelector = (state: DataState) => state.mailPage.mailForm;
export const referralCodeSelector = (state: DataState) => state?.referralPage?.referralCode;
export const partnerSelector = (state: DataState) => state?.mailPage?.partner?.name;
export const partnerOffersSelector = (state: DataState) => state?.mailPage?.partner?.partnerOffers;
export const mailSavedReferentielSelector = (state: DataState) => state?.mailSavedPage?.referentiel;
export const formulaChoicesReferentielSelector = (state: DataState) =>
  state?.formulaChoicesPage?.referentiel;
export const selectedFormulaOfferSelector = (state: DataState) => {
  if (state?.formulaChoicesPage?.referentiel?.offers === undefined) return undefined;
  for (const offer of state?.formulaChoicesPage?.referentiel?.offers) {
    if (offer.longTitle === state.formulaChoicesPage?.offer?.longTitle) {
      if (offer.offers === undefined) return undefined;
      for (const formula of offer.offers) {
        if (
          formula.formula === state.formulaChoicesPage?.offer?.formula?.formula &&
          formula.card === state.formulaChoicesPage?.offer?.formula?.card
        )
          return formula;
      }
    }
  }
  return undefined;
};
export const selectedOfferSelector = (state: DataState) => {
  if (state?.formulaChoicesPage?.referentiel?.offers === undefined) return undefined;
  for (const offer of state?.formulaChoicesPage?.referentiel?.offers) {
    if (offer.longTitle === state.formulaChoicesPage?.offer?.longTitle) return offer;
  }
  return undefined;
};

export const commitmentPrinciplesReferentielSelector = (state: DataState) =>
  state?.commitmentPrinciplePage?.referentiel;
export const commitmentAccountReferentielSelector = (state: DataState) =>
  state?.commitmentAccountPage?.referentiel;
export const trackingChoicesReferentielSelector = (state: DataState) =>
  state?.trackingChoicesPage?.referentiel;
export const selectedTrackingChoiceSelector = (state: DataState) =>
  state?.trackingChoicesPage?.trackingChoice;
export const commitmentCardReferentielSelector = (state: DataState) =>
  state?.commitmentCardPage?.referentiel;
export const associationChoicesReferentielSelector = (state: DataState) =>
  state?.associationChoicesPage?.referentiel;
export const associationChoicesSelector = (state: DataState) => state?.associationChoicesPage;
export const donationChoicesReferentielSelector = (state: DataState) =>
  state?.donationChoicesPage?.referentiel;
export const donationFormSelector = (state: DataState) => state.donationChoicesPage.donationForm;
export const summaryReferentielSelector = (state: DataState) => state?.summaryPage?.referentiel;
export const finalReferentielSelector = (state: DataState) => state?.finalPage?.referentiel;
