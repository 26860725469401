import {combineReducers, createReducer, on} from '@ngrx/store';
import {DataActions} from './data.actions';
import {
  createFormGroupState,
  onNgrxForms,
  onNgrxFormsAction,
  setValue,
  SetValueAction,
  updateGroup,
  validate,
} from 'ngrx-forms';

export const loadingReducer = createReducer(
  true,
  on(DataActions.startLoading, (loading) => {
    return true;
  }),
  on(DataActions.stopLoading, (loading) => {
    return false;
  }),
);

export const transverseReducer = createReducer(
  undefined,
  on(DataActions.setTransverseReferentiel, (oldReferentiel, {referentiel}) => referentiel),
);

export const errorPageReducers = createReducer(
  undefined,
  on(DataActions.setBrowserErrorReferentiel, (oldReferentiel, {referentiel}) => ({
    ...oldReferentiel,
    referentiel,
  })),
  on(DataActions.setLinkExpiredReferentiel, (oldReferentiel, {referentiel}) => ({
    ...oldReferentiel,
    referentiel,
  })),
);

export const referralPageReducers = createReducer(
  undefined,
  on(DataActions.setReferralReferentiel, (oldReferentiel, {referentiel}) => ({
    ...oldReferentiel,
    referentiel,
  })),
  on(DataActions.setReferralCodeSuccess, (state, {referralCode}) => ({
    ...state,
    referralCode,
    error: null,
  })),
  on(DataActions.setReferralCodeFailure, (state, {error}) => ({
    ...state,
    error,
  })),
);

export const stepsPresentationPageReducers = createReducer(
  undefined,
  on(DataActions.setStepsPresentationReferentiel, (oldReferentiel, {referentiel}) => ({
    ...oldReferentiel,
    referentiel,
  })),
);

const groupMailControl = createFormGroupState('EMAIL', {
  email: '',
  code: '',
});

const updateMailGroupControl = updateGroup<{email; code}>({});

export const mailPageReducers = combineReducers({
  referentiel: createReducer(
    undefined,
    on(DataActions.setMailReferentiel, (oldReferentiel, {referentiel}) => referentiel),
  ),
  mailForm: createReducer(
    groupMailControl,
    onNgrxForms(),
    onNgrxFormsAction(SetValueAction, updateMailGroupControl),
    on(DataActions.setEmail, (form, {email}) => {
      return {
        ...form,
        controls: {
          ...form.controls,
          email: setValue(form.controls.email, email),
        },
      };
    }),
    on(DataActions.setCode, (form, {code}) => {
      return {
        ...form,
        controls: {
          ...form.controls,
          code: setValue(form.controls.code, code),
        },
      };
    }),
  ),
  partner: createReducer(
    undefined,
    on(DataActions.setPartnerOffers, (state, {partnerOffers}) => {
      return {
        ...state,
        partnerOffers,
      };
    }),
    on(DataActions.setCode, (state, {code}) => {
      return {
        ...state,
        code: code,
      };
    }),
    on(DataActions.setPartner, (state, {partner}) => {
      return {
        ...state,
        name: partner,
      };
    }),
  ),
});

export const mailSavedPageReducers = createReducer(
  undefined,
  on(DataActions.setMailSavedReferentiel, (oldReferentiel, {referentiel}) => ({
    ...oldReferentiel,
    referentiel,
  })),
);

export const formulaChoicesPageReducers = combineReducers({
  referentiel: createReducer(
    undefined,
    on(DataActions.setFormulaChoiceReferentiel, (oldReferentiel, {referentiel}) => referentiel),
  ),
  offer: createReducer(
    {
      longTitle: undefined,
      formula: {
        formula: undefined,
        card: undefined,
      },
    },
    on(DataActions.setOffer, (oldOffer, {offer}) => offer),
  ),
});

export const commitmentPrinciplePageReducers = createReducer(
  undefined,
  on(DataActions.setCommitmentPrinciplesReferentiel, (oldReferentiel, {referentiel}) => ({
    ...oldReferentiel,
    referentiel,
  })),
);

export const commitmentAccountPageReducers = createReducer(
  undefined,
  on(DataActions.setCommitmentAccountReferentiel, (oldReferentiel, {referentiel}) => ({
    ...oldReferentiel,
    referentiel,
  })),
);

export const trackingChoicesPageReducers = createReducer(
  undefined,
  on(DataActions.setTrackingChoicesReferentiel, (oldReferentiel, {referentiel}) => ({
    ...oldReferentiel,
    referentiel,
  })),
  on(DataActions.setSelectedTrackingChoices, (oldReferentiel, {trackingChoice}) => ({
    ...oldReferentiel,
    trackingChoice,
  })),
);

export const commitmentCardPageReducers = createReducer(
  undefined,
  on(DataActions.setCommitmentCardReferentiel, (oldReferentiel, {referentiel}) => ({
    ...oldReferentiel,
    referentiel,
  })),
);

export const associationChoicesPageReducers = createReducer(
  undefined,
  on(DataActions.setSelectedAssociation, (oldReferentiel, {association}) => ({
    ...oldReferentiel,
    association,
  })),
  on(DataActions.setAssociationChoicesReferentiel, (oldReferentiel, {referentiel}) => ({
    ...oldReferentiel,
    referentiel,
  })),
);

const groupDonationControl = createFormGroupState('DONATION', {
  donation: undefined,
  donationPerOperation: undefined,
  donationCeiling: undefined,
});

const updateDonationGroupControl = updateGroup<{donation; donationPerOperation; donationCeiling}>({
  donation: validate([]),
  donationPerOperation: validate([]),
});

export const donationChoicesPageReducers = combineReducers({
  referentiel: createReducer(
    undefined,
    on(DataActions.setDonationChoicesReferentiel, (oldReferentiel, {referentiel}) => referentiel),
  ),
  donationForm: createReducer(
    groupDonationControl,
    onNgrxForms(),
    onNgrxFormsAction(SetValueAction, updateDonationGroupControl),
    on(DataActions.setDonation, (form, {donation, donationPerOperation, donationCeiling}) => {
      return {
        ...form,
        controls: {
          donation: setValue(form.controls.donation, donation),
          donationPerOperation: setValue(
            form.controls.donationPerOperation,
            donationPerOperation ?? null,
          ),
          donationCeiling: setValue(form.controls.donationCeiling, donationCeiling ?? null),
        },
      };
    }),
    on(
      DataActions.setDonationWithValidation,
      (form, {donation, donationPerOperation, donationCeiling}) => {
        return {
          ...form,
          controls: {
            donation: setValue(form.controls.donation, donation),
            donationPerOperation: setValue(
              form.controls.donationPerOperation,
              donationPerOperation ?? null,
            ),
            donationCeiling: setValue(form.controls.donationCeiling, donationCeiling ?? null),
          },
        };
      },
    ),
  ),
});

export const summaryPageReducers = combineReducers({
  referentiel: createReducer(
    undefined,
    on(DataActions.setSummaryReferentiel, (oldReferentiel, {referentiel}) => referentiel),
  ),
  kycRedirected: createReducer(
    false,
    on(DataActions.kycRedirected, () => {
      return true;
    }),
  ),
});

export const finalPageReducers = createReducer(
  undefined,
  on(DataActions.setFinalReferentiel, (oldReferentiel, {referentiel}) => ({
    ...oldReferentiel,
    referentiel,
  })),
);
